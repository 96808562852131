@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;700&family=Inter:wght@300;400;500;700&display=swap');
.ql-editor{
    height: 276px;
    overflow: auto;
    font-family: 'Inter', sans-serif;
    font-size: .875rem;
    line-height: 24px;
}
.ql-editor p {
    margin: .5rem 0;
}

.ql-editor::-webkit-scrollbar {
    width: .25rem;
}
.ql-editor::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.ql-editor::-webkit-scrollbar-thumb {
    background-color: #5b5a5a;
    border: 1px solid slategrey;
    border-radius: 8px;
}

.ql-editor >p {
    line-height: 24px;
}